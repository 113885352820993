import { Card, Col, Row } from 'react-bootstrap'
import Header from '../Header/index.js';
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
// import Structure from '../Structure'
import "../Home/home.css"
import { Link } from 'react-router-dom';
import { rutas } from '../../lib/routes/routes.js';
import SortableBox from '../../subComponents/general/sortable.js';
import { LuLineChart, LuTowerControl } from 'react-icons/lu';
import { FaBoxOpen, FaChartLine, FaTruck } from 'react-icons/fa';
import { FaHandHoldingDollar, FaMagnifyingGlassChart } from 'react-icons/fa6';
import { HiOutlineTicket } from 'react-icons/hi';
import { RiRouteLine } from 'react-icons/ri';
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2';
import { PiWebhooksLogoBold } from 'react-icons/pi';
import { MdOutlineManageAccounts } from 'react-icons/md';
import { TbAlertSquareRounded } from 'react-icons/tb';

const Home = (props) => {
  const session = useSelector(state => state.miusuario)
  const pais = useSelector(state => state.pais)
  const dispatch = useDispatch()
  const modoUsuario = useSelector(state => state.modo)

  const itemBoxProveedores = [
    {
      titulo: "Torre Control Envío",
      descripcion: "Monitorea y controla el OTIF (On-Time-In-Full) de todos y cada uno de los envíos mensuales...",
      itemIcon: <LuTowerControl style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "control"
    },
    {
      titulo: "OTIF Proveedores",
      descripcion: "Mide y gestiona el desempeño OTIF de cada uno de tus proveedores o flota propia...",
      itemIcon: <FaMagnifyingGlassChart style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "proveedores"
    },
    {
      titulo: "Auditorías Entregas",
      descripcion: "Supervisa y controla en tiempo real el estado de las entregas a tus clientes y la ubicación de los vehículos...",
      itemIcon: <FaBoxOpen style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "entregas"
    },
    {
      titulo: "Ticket Incidencia",
      descripcion: "Mide, registra y controla en tiempo real todos los envíos con incidencias de no entrega conforme...",
      itemIcon: <HiOutlineTicket style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ticket"
    },
    {
      titulo: "Tarifas - Cotizador",
      descripcion: "Cotiza y compara valores de flete online, tiempos de transito y rutas segun proveedores...",
      itemIcon: <FaHandHoldingDollar style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "tarifas"
    },
    {
      titulo: "Flota Propia",
      descripcion: "gestiona y administra tu propia flota de vehículos como si fueran proveedores de transporte externo...",
      itemIcon: <FaTruck style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "flota"
    },
    {
      titulo: "Optimizador de Ruta",
      descripcion: "Gestiona eficientemente las rutas de distribución para tus envíos a través de tu propia flota de vehículos...",
      itemIcon: <RiRouteLine style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "rutas"
    },
    {
      titulo: "Órdenes - Rutas",
      descripcion: "Ingresa tus envíos y notifica a tus proveedores sobre la asignación de envíos como las exceptivas...",
      itemIcon: <HiOutlineClipboardDocumentList style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ordenes"
    },
    {
      titulo: "Integraciones",
      descripcion: "Integra tus sistemas o tu pagina vía API u otros métodos con nuestra solución de Última Milla...",
      itemIcon: <PiWebhooksLogoBold style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "integraciones"
    },
    {
      titulo: "Configuraciones",
      descripcion: "Personaliza y configura la plataforma según tus necesidades logísticas, incluyendo la configuración...",
      itemIcon: <MdOutlineManageAccounts style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "configuracion"
    },
    {
      titulo: "Notificaciones - Alertas",
      descripcion: "Personaliza y configura notificaciones urgentes que requieren tu atención a través de WhatsApp y otros medios...",
      itemIcon: <TbAlertSquareRounded style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "notificaciones"
    }
  ]

  const itemBoxCliente = [
    {
      titulo: "Torre Control Envío",
      descripcion: "Monitorea y controla el OTIF (On-Time-In-Full) de todos y cada uno de los envíos mensuales...",
      itemIcon: <LuTowerControl style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "control"
    },
    {
      titulo: "OTIF Proveedores",
      descripcion: "Mide y gestiona el desempeño OTIF de cada uno de tus proveedores o flota propia...",
      itemIcon: <FaMagnifyingGlassChart style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "proveedores"
    },
    {
      titulo: "Auditorías Entregas",
      descripcion: "Supervisa y controla en tiempo real el estado de las entregas a tus clientes y la ubicación de los vehículos...",
      itemIcon: <FaBoxOpen style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "entregas"
    },
    {
      titulo: "Ticket Incidencia",
      descripcion: "Mide, registra y controla en tiempo real todos los envíos con incidencias de no entrega conforme...",
      itemIcon: <HiOutlineTicket style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ticket"
    },
    {
      titulo: "Tarifas - Cotizador",
      descripcion: "Cotiza y compara valores de flete online, tiempos de transito y rutas segun proveedores...",
      itemIcon: <FaHandHoldingDollar style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "tarifas"
    },
    {
      titulo: "Flota Propia",
      descripcion: "gestiona y administra tu propia flota de vehículos como si fueran proveedores de transporte externo...",
      itemIcon: <FaTruck style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "flota"
    },
    {
      titulo: "Optimizador de Ruta",
      descripcion: "Gestiona eficientemente las rutas de distribución para tus envíos a través de tu propia flota de vehículos...",
      itemIcon: <RiRouteLine style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "rutas"
    },
    {
      titulo: "Órdenes - Rutas",
      descripcion: "Ingresa tus envíos y notifica a tus proveedores sobre la asignación de envíos como las exceptivas...",
      itemIcon: <HiOutlineClipboardDocumentList style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ordenes"
    },
    {
      titulo: "Integraciones",
      descripcion: "Integra tus sistemas o tu pagina vía API u otros métodos con nuestra solución de Última Milla...",
      itemIcon: <PiWebhooksLogoBold style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "integraciones"
    },
    {
      titulo: "Configuraciones",
      descripcion: "Personaliza y configura la plataforma según tus necesidades logísticas, incluyendo la configuración...",
      itemIcon: <MdOutlineManageAccounts style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "configuracion"
    },
    {
      titulo: "Notificaciones - Alertas",
      descripcion: "Personaliza y configura notificaciones urgentes que requieren tu atención a través de WhatsApp y otros medios...",
      itemIcon: <TbAlertSquareRounded style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "notificaciones"
    }
  ]
  
  const itemBoxCourriers = [
    {
      titulo: "Torre Control Envío",
      descripcion: "Monitorea y controla el OTIF (On-Time-In-Full) de todos y cada uno de los envíos mensuales...",
      itemIcon: <LuTowerControl style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "control"
    },
    {
      titulo: "OTIF Proveedores",
      descripcion: "Mide y gestiona el desempeño OTIF de cada uno de tus proveedores o flota propia...",
      itemIcon: <FaMagnifyingGlassChart style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "proveedores"
    },
    {
      titulo: "Auditorías Entregas",
      descripcion: "Supervisa y controla en tiempo real el estado de las entregas a tus clientes y la ubicación de los vehículos...",
      itemIcon: <FaBoxOpen style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "entregas"
    },
    {
      titulo: "Ticket Incidencia",
      descripcion: "Mide, registra y controla en tiempo real todos los envíos con incidencias de no entrega conforme...",
      itemIcon: <HiOutlineTicket style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ticket"
    },
    {
      titulo: "Tarifas - Cotizador",
      descripcion: "Cotiza y compara valores de flete online, tiempos de transito y rutas segun proveedores...",
      itemIcon: <FaHandHoldingDollar style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "tarifas"
    },
    {
      titulo: "Flota Propia",
      descripcion: "Gestiona y administra tu propia flota de vehículos como si fueran proveedores de transporte externo...",
      itemIcon: <FaTruck style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "flota"
    },
    {
      titulo: "Optimizador de Ruta",
      descripcion: "Gestiona eficientemente las rutas de distribución para tus envíos a través de tu propia flota de vehículos...",
      itemIcon: <RiRouteLine style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "rutas"
    },
    {
      titulo: "Órdenes - Rutas",
      descripcion: "Ingresa tus envíos y notifica a tus proveedores sobre la asignación de envíos como las exceptivas...",
      itemIcon: <HiOutlineClipboardDocumentList style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ordenes"
    },
    {
      titulo: "Integraciones",
      descripcion: "Integra tus sistemas o tu pagina vía API u otros métodos con nuestra solución de Última Milla...",
      itemIcon: <PiWebhooksLogoBold style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "integraciones"
    },
    {
      titulo: "Configuraciones",
      descripcion: "Personaliza y configura la plataforma según tus necesidades logísticas, incluyendo la configuración...",
      itemIcon: <MdOutlineManageAccounts style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "configuracion"
    },
    {
      titulo: "Notificaciones - Alertas",
      descripcion: "Personaliza y configura notificaciones urgentes que requieren tu atención a través de WhatsApp y otros medios...",
      itemIcon: <TbAlertSquareRounded style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "notificaciones"
    }
  ]


  // const render = () => {
  return (
    <>
      <div className='homepage p-4'>
        <Row className='mb-3'>
          <Col xs={6}>
          <span style={{ fontWeight: 900, fontSize: 35, verticalAlign: "middle", color: '#1B3665' }}>
            ¡BIENVENIDO! 
          </span>
          </Col>
          <Col xs={6} className='text-right '>
          <img
              alt="QuestionIcon"
              src="images/sidebar/Question.svg"
              style={{ height: 35 }} />
          </Col>
        </Row>

        <Row>

        {modoUsuario === 'proveedor' && (<>
          {itemBoxProveedores.map((box, i) => {
            // return false
            return <Col key={`item-${i}`} md={4}>
              <Link to={`/${rutas.landing.actividad.slug}/${box.slug ? box.slug : ""}`}>
                <Card className='itemBox p-3 mb-3' style={{ height:170 }}>
                  <Row>
                    <Col xs={3}>
                      {box.itemIcon}
                    </Col>
                    <Col xs={9} style={{ paddingLeft: 0, paddingRight: 30 }}>
                      <span style={{ fontWeight: 700, fontSize: 22, color: '#1B3665' }}>
                        {box.titulo}
                      </span>
                      <p className='mt-0' >
                        {box.descripcion}
                      <p className='text-right' style={{ fontWeight: 400, fontSize: 10, color: '#1B3665' }}><b>(ver más)</b></p>
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Link>
            </Col>
          })}
          </>)}

          {modoUsuario === 'cliente' && (<>
          {itemBoxCliente.map((box, i) => {
            // return false
            return <Col key={`item-${i}`} md={4}>
              <Link to={`/${rutas.landing.actividad.slug}/${box.slug ? box.slug : ""}`}>
                <Card className='itemBox p-3 mb-3' style={{ height:170 }}>
                  <Row>
                    <Col xs={3}>
                      {box.itemIcon}
                    </Col>
                    <Col xs={9} style={{ paddingLeft: 0, paddingRight: 30 }}>
                      <span style={{ fontWeight: 700, fontSize: 22, color: '#1B3665' }}>
                        {box.titulo}
                      </span>
                      <p className='mt-0' >
                        {box.descripcion}
                      <p className='text-right' style={{ fontWeight: 400, fontSize: 10, color: '#1B3665' }}><b>(ver más)</b></p>
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Link>
            </Col>
          })}
          </>)}
          
          {modoUsuario === 'courriers' && (<>
          {itemBoxCourriers.map((box, i) => {
            // return false
            return <Col key={`item-${i}`} md={4}>
              <Link to={`/${rutas.landing.actividad.slug}/${box.slug ? box.slug : ""}`}>
                <Card className='itemBox p-3 mb-3' style={{ height:170 }}>
                  <Row>
                    <Col xs={3}>
                      {box.itemIcon}
                    </Col>
                    <Col xs={9} style={{ paddingLeft: 0, paddingRight: 30 }}>
                      <span style={{ fontWeight: 700, fontSize: 22, color: '#1B3665' }}>
                        {box.titulo}
                      </span>
                      <p className='mt-0' >
                        {box.descripcion}
                      <p className='text-right' style={{ fontWeight: 400, fontSize: 10, color: '#1B3665' }}><b>(ver más)</b></p>
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Link>
            </Col>
          })}
          </>)}
        </Row>
        {/* <p className='version text-center' style={{ fontWeight: 400, fontSize: 14, color: '#1B3665', marginTop: 25 }}>VERSIÓN 4.1</p> */}
      </div>
    </>
  )
}


export default Home