import { useState } from "react"
import { Button, ButtonGroup, Card, Col, Dropdown, Form, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { modos_sistema, url_images } from "../../lib/global/data"
import { Link, useNavigate } from "react-router-dom"
import { cerrarSesion } from "../../redux/actions/session"
import { version_app } from "../../lib/backend/data"
import { rutas } from "../../lib/routes/routes"
import { cambiarModo } from "../../redux/actions/cambiarModo"
import { estilo_last_mile } from '../../lib/global/styles';
import BuscadorAvanzadoOrdenes from "../../subComponents/ordenes/buscador_avanzado"
import { FaCalculator, FaPlusCircle, FaRegClock } from "react-icons/fa"
import { BsFillSendCheckFill } from "react-icons/bs"
import { IoIosHelpCircle } from "react-icons/io"
const { useDispatch, useSelector } = require("react-redux")

const TopBar = () => {
    const session = useSelector(state => state.miusuario)
    
    const modo_default = useSelector(state => state.modo)
    const [modo, setModo] = useState(modo_default);

    const [color, setColor] = useState(estilo_last_mile.color_primary);
    const [icon, setIcon] = useState("fa-solid fa-truck-field");
    const navigate = useNavigate();
    const user = session
    const dispatch = useDispatch()
    let logotipo_empresa = ''

    if(session){
      if(session.data){
        if(session.data.empresa){
          if(session.data.empresa.logotipo) logotipo_empresa = session.data.empresa.logotipo
        }
      }
    }

    
        const handleChangeMode = (mode) => {
            const nuevo_modo = mode
            setModo(nuevo_modo);
            dispatch(cambiarModo(nuevo_modo));
            navigate('/')
        }

        const checkModeDisabled = (val) => {
            return modo === val ? false : true
        }

        const tobWidgetsPorModo = () => {

            const estilo_tip = { fontSize: 11, fontWeight: "bold" }
            switch (modo) {
                case "courriers":
                    return <div>
                        <Row>
                            <Col >
                            <Link to={`${rutas.crear_orden.slug}`}>
                            <Card className="text-center p-2">
                                <FaCalculator className="mb-1" style={{ margin: "0 auto" }} />
                                <h5 style={estilo_tip} className="mb-0">COTIZAR</h5>
                            </Card>
                            </Link>
                            </Col>
                            <Col >
                            <Link to={`${rutas.ordenes.slug}?quickstatus=pending`}>
                            <Card className="text-center p-2">
                                <FaRegClock className="mb-1" style={{ margin: "0 auto" }} />
                                <h5 style={estilo_tip} className="mb-0">PENDIENTES</h5>
                            </Card>
                            </Link>
                            </Col>
                            <Col >
                            <Link to={`${rutas.ordenes.slug}?quickstatus=gestioned`}>
                            <Card className="text-center p-2">
                                <BsFillSendCheckFill className="mb-1" style={{ margin: "0 auto" }} />
                                <h5 style={estilo_tip} className="mb-0">GESTIONADOS</h5>
                            </Card>
                            </Link>
                            </Col>
                            <Col >
                            <Link to={`/`}>
                            <Card className="text-center p-2">
                                <IoIosHelpCircle className="mb-1" style={{ margin: "0 auto" }} />
                                <h5 style={estilo_tip} className="mb-0">AYUDA</h5>
                            </Card>
                            </Link>
                            </Col>
                        </Row>
                    </div>
                default:
                    break;
            }
        }

    return <div className='p-3 shadow header bg-white'>
    <Row>
        <Col md={2} ><div className="d-block text-left">
            <img style={{ width: "100%", maxWidth: 150 }} src={`${url_images}/logoColor.svg`} />
            <p className="mb-0 text-primary" style={{ fontWeight:700, fontSize: 10, textAlign: "left", marginTop: -5, paddingLeft:65 }}><b>VERSIÓN {version_app}</b></p>
            </div>
        </Col>
        <Col md={3}><div className="pr-3" style={{ width: "100%" }}><BuscadorAvanzadoOrdenes /></div></Col>
        <Col md={1} className="centrador">{logotipo_empresa ? <img style={{ width: "100%" }} src={logotipo_empresa} /> : false }</Col>
        
        <Col md={6} xs={9} className="text-right d-flex align-items-center justify-content-end">
        
                {/* <button type="button"  className="text-left btn btn-light btn-sm shadow mr-2" data-bs-toggle="button" onClick={() => handleChangeMode(modos_sistema.cliente.slug)}>
            <h6 className="p-0 m-0" style={{fontSize:14}}><i className="fa-solid fa-truck-field"></i><b> Gestión de transporte</b></h6>
            <p className="p-0 m-0" style={{fontSize:11}}>Enfoque en gestión de flota</p>
        </button>

        <button type="button" className="text-left btn btn-light btn-sm shadow" data-bs-toggle="button" onClick={() => handleChangeMode(modos_sistema.proveedor.slug)}>
            <h6 className="m-0 p-0" style={{fontSize:14}}><i className="fa-solid fa-user-tie"></i><b> Gestión de carga</b></h6>
            <p className="m-0 p-0" style={{fontSize:11}}>Enfoque en gestión de carga</p>
        </button>
        <button type="button" className="text-left btn btn-light btn-sm shadow" data-bs-toggle="button" onClick={() => handleChangeMode(modos_sistema.courriers.slug)}>
            <h6 className="m-0 p-0" style={{fontSize:14}}><i className="fa-solid fa-user-tie"></i><b> Courriers</b></h6>
            <p className="m-0 p-0" style={{fontSize:11}}>Enfoque en gestión de carga</p>
        </button> */}
        {tobWidgetsPorModo()}

        <Dropdown>                
            <Dropdown.Toggle
                className='dropdown-header'
                variant="white"
                style={{ padding:8 }}>
                <div className=''>
                <img src={`${url_images}/sidebar/tuerca.svg`} />
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ fontSize: 14, fontWeight: 400, color: '#1B3665' }}>
                <Dropdown.Item eventKey="1" as={Link} to={`/${rutas.avanzada.slug}`}>General</Dropdown.Item>
                <Dropdown.Item eventKey="2" as={Link} to={`/${rutas.usuarios.slug}`}>Empresa</Dropdown.Item>
                {/* <Dropdown.Item eventKey="3" as={Link} to={`/${rutas.excel_formats.slug}`}>Formatos excel</Dropdown.Item> */}
            </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
            <Dropdown.Toggle
                className='dropdown-header'
                variant="white"
                style={{ background: 'none', padding: 0, display: "inline-block" }}>
                <div className='user-box d-inline-flex align-items-center'>
                <img className='icon' alt="inicioIcon" src={`${url_images}/sidebar/iconoperfil.svg`} style={{ height: 40, marginRight: 16 }} />
                <div className='usuario d-flex flex-column align-items-center' style={{ background: 'none', color: '#1B3665' }}>
                    <div style={{ fontSize: 16, fontWeight: 700, whiteSpace: 'nowrap' }}>{user.data.nombres}</div>
                    <div style={{ fontSize: 12, fontWeight: 400, whiteSpace: 'nowrap' }}>{user.data.email}</div>
                </div>
                <img className='icon' alt="inicioIcon" src={`${url_images}/sidebar/flecha.svg`} style={{ height: 21, marginLeft: 16 }} />
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ fontSize: 14, fontWeight: 400, color: '#1B3665' }}>
                <Dropdown.Item eventKey="1" as={Link} to="/account">Mi cuenta</Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={() => dispatch(cerrarSesion())}>Cerrar Sesión</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        </Col>
    </Row>
    </div>
}

export default TopBar