import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto } from "../../lib/helpers/helpers"
import { getDireccion, obtenerGeoValor } from "../../lib/helpers/pedidos/pedidos"
import MostrarGeoDatos from "./box_geo_datos"
import { FiMapPin } from "react-icons/fi"
import { BiUser } from "react-icons/bi"
import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { urlapi } from "../../lib/backend/data"
import { toast } from "react-toastify"
import { cerrarSesion } from "../../redux/actions/session"
import { useDispatch, useSelector } from "react-redux"
import { CiBoxes } from "react-icons/ci"
import { BsBoxSeam } from "react-icons/bs"

const BoxList = (props) => {
    const {
        hideAddress,
        partialAddress,
        typeView,
        selected,
        setSelected,
    } = props;
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const token = session.tokenSession
    const [orden, setOrden] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const pedido = props.pedido
    const seleccionado = props.seleccionado ? props.seleccionado : false
    const showGeoData = props.showGeoData ? props.showGeoData : false
    const tipo_vista = typeof typeView !== "undefined" ? typeView : false
    const hide_address = typeof hideAddress !== "undefined" ? hideAddress : false
    const partial_address = typeof partialAddress !== "undefined" ? partialAddress : false
    let destinatario = ''
    let titulo_estado = ''
    let titulo_sub_estado = ''
    let conductor = ''
    let patente = ''
    let color_estado = '#bebebe'
    let smallSizeFont = 12

    // const getIdFromUrl = () => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     return urlParams.get('id');
    // };

    // const id = getIdFromUrl();


    if(typeof pedido.orden === 'object'){
        if(typeof pedido.orden.billing === 'object'){
            destinatario = pedido.orden.billing.first_name
        }
    }

    const clickPedido = (id) => {
        if(props.onClickPedido) return props.onClickPedido(id)
        if (selected.includes(id)) {
            setSelected(prevSelected => prevSelected.filter(pedidoId => pedidoId !== id));
        } else {
            setSelected(prevSelected => [...prevSelected, id]);
        }
    }

      console.log('selected', selected)

    if(pedido.estado_entrega){
        if(typeof pedido.estado_entrega === 'object'){
            if(pedido.estado_entrega.titulo) titulo_estado = pedido.estado_entrega.titulo
            if(pedido.estado_entrega.color) color_estado = pedido.estado_entrega.color
        }
    }

    if(pedido.sub_estado_entrega){
        if(typeof pedido.sub_estado_entrega === 'object'){
            if(pedido.sub_estado_entrega.titulo) titulo_sub_estado = pedido.sub_estado_entrega.titulo
        }
    }

    if(pedido.conductor){
        if(typeof pedido.conductor === 'object'){
            if(pedido.conductor.nombres) conductor = pedido.conductor.nombres
            if(pedido.conductor.patente) patente = pedido.conductor.patente
        }
    }

    const mostrarVehiculo = (conductor, patente) => {
        if(!conductor) return "Sin asignar"
        let string = conductor
        if(patente) string = `${string} ${patente}`
        return string
    }

    const mostrarDireccion = (pedido) => {
        if(partial_address === true){
            let direccion = ''
            const nivel3 = obtenerGeoValor(pedido,'level3')
            if(nivel3) direccion = `${direccion} ${nivel3}`

            const nivel2 = obtenerGeoValor(pedido,'level2')
            if(nivel2) direccion = `${direccion} ${nivel2}`

            const nivel1 = obtenerGeoValor(pedido,'level1')
            if(nivel1) direccion = `${direccion} ${nivel1}`
            return direccion
        } else {
            return getDireccion(pedido)
        }
    }

    const obtenerOrden = async () => {
        const id = pedido._id
        console.log('id',id)
        if(!id) return setLoadingDetalle(false)
        setLoadingDetalle(true)
        return fetch(`${urlapi}/ordenes/details-full?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res.orden){
                if(res.orden) {
                    setOrden(res.orden)
                    console.log('ordenes',res.orden)
                  }

            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }
    console.log('orden',orden)

    useEffect(() => {
      obtenerOrden()
    },[])

    const MostrarGeoDatosComponent = () => {
        if(showGeoData !== true) return false
        return <MostrarGeoDatos pedido={pedido} selected={seleccionado}/>
    }

    let string_estado = `${titulo_estado} ${titulo_sub_estado}`
    if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"

    if(tipo_vista === "simple") return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={` p-3 ${seleccionado === true ? 'text-white' : 'text-primary'}`} onClick={() => clickPedido(pedido._id)}>
    <Row>
        <Col xs={7}><h6 style={{ textTransform: "uppercase", fontSize: smallSizeFont, fontWeight: "bold" }} className="mb-0"><BiUser /> {destinatario}</h6></Col>
        <Col xs={5} className="text-right"><h6 style={{ fontSize:  smallSizeFont, fontWeight: "bold" }} className="mb-0">{pedido.pedido}</h6></Col>
        <Col xs={6} className="text-right"><h6 style={{ fontSize: smallSizeFont, fontWeight : "bold" }} className="mb-2">{patente}</h6></Col>
        { !hide_address ? <Col md={12}><h6 style={{ fontSize: smallSizeFont, color: "#aeaeae" }} className="mb-0"><FiMapPin /> {mostrarDireccion(pedido)}</h6></Col> : false }
    </Row>
    </Card>


    if(tipo_vista === "manifest") return (<>
        <Row>
            <Col xs={6}><h6 style={{ textTransform: "uppercase", fontSize: 14, fontWeight: "bold" }} className="mb-2"><BiUser /> {destinatario}</h6></Col>
            <Col xs={6}>
                <div className="text-right">
                    <h6 style={{ fontSize: smallSizeFont }} className="mb-2"><b>Ref:</b> {pedido.pedido}</h6>
                </div>
            </Col>
            <Col xs={12}>
            <table className="table">
                <thead className="">
                    <tr>
                    <th style={{ fontSize: smallSizeFont }}><b>Bultos:</b></th>
                    <th style={{ fontSize: smallSizeFont }}><b>Peso:</b></th>
                    <th style={{ fontSize: smallSizeFont }}><b>Volumen:</b></th>
                    <th style={{ fontSize: smallSizeFont }}><b>Origen (O):</b></th>
                    <th style={{ fontSize: smallSizeFont }}><b>O.Lvl 1:</b></th>
                    <th style={{ fontSize: smallSizeFont }}><b>O.Lvl 2:</b></th>
                    <th style={{ fontSize: smallSizeFont }}><b>O.Lvl 3:</b></th>
                    <th style={{ fontSize: smallSizeFont }}><b>Destino (D):</b></th>
                    <th style={{ fontSize: smallSizeFont }}><b>D.Lvl 1:</b></th>
                    <th style={{ fontSize: smallSizeFont }}><b>D.Lvl 2:</b></th>
                    <th style={{ fontSize: smallSizeFont }}><b>D.Lvl 3:</b></th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="text-center">{orden?.bultos ? orden.bultos : '--'}</td>
                    <td>{orden?.peso ? orden.peso : '--'}</td>
                    <td>{orden?.volumen ? orden.volumen : '--'}</td>
                    <td>{orden?.orden?.geo_datos_origen?.address_1 ? orden.orden.geo_datos_origen.address_1 : '--'}</td>
                    <td>{orden?.orden?.geo_datos_origen?.level1 ? orden.orden.geo_datos_origen.level1 : '--'}</td>
                    <td>{orden?.orden?.geo_datos_origen?.level2 ? orden.orden.geo_datos_origen.level2 : '--'}</td>
                    <td>{orden?.orden?.geo_datos_origen?.level3 ? orden.orden.geo_datos_origen.level3 : '--'}</td>
                    <td>{orden?.orden?.billing?.address_1 ? orden.orden.billing.address_1 : '--'}</td>
                    <td>{orden?.orden?.geo_datos?.level1 ? orden.orden.geo_datos.level1 : '--'}</td>
                    <td>{orden?.orden?.geo_datos?.level2 ? orden.orden.geo_datos.level2 : '--'}</td>
                    <td>{orden?.orden?.geo_datos?.level3 ? orden.orden.geo_datos.level3 : '--'}</td>
                </tr>
                </tbody>
            </table>
            </Col> 

            {/* <Col xs={12}>
            <div className="d-flex">
            <BsBoxSeam />
            <h6 style={{ fontSize: smallSizeFont }} className="mb-0 ml-2"><b>Bultos:</b> {orden.bultos ?  orden.bultos : false}</h6>
            <h6 style={{ fontSize: smallSizeFont }} className="mb-0 ml-4"><b>Peso:</b> {orden.peso ?  orden.peso : 'Sin Información'}</h6>
            <h6 style={{ fontSize: smallSizeFont }} className="mb-2 ml-4"><b>Volumen:</b> {orden.volumen ?  orden.volumen: 'Sin Información'}</h6>
            </div>
            </Col>
            <Col xs={12}>
            <div className="d-flex">
            <FiMapPin />
            <h6 style={{ fontSize: smallSizeFont }} className="mb-0 ml-2"><b>Geo Datos:</b></h6>
            </div>
            </Col>
            <Col xs={6} className=""><h6 style={{ fontSize: smallSizeFont, fontWeight : "bold" }} className="mb-0">{patente}</h6>
            <div>
            <p style={{ fontSize: smallSizeFont }} className="mb-0 ml-2">
             Origen: {orden?.orden?.geo_datos_origen?.address_1 ? orden.orden.geo_datos_origen.address_1 : false}
            </p>
            <Card className="">
            <p style={{ fontSize: smallSizeFont }} className="mb-0 ml-2">Level 1: {orden?.orden?.geo_datos_origen?.level1 ?  orden?.orden?.geo_datos_origen?.level1 : false}</p>
            <p style={{ fontSize: smallSizeFont }} className="mb-0 ml-2">Level 2: {orden?.orden?.geo_datos_origen?.level2 ?  orden?.orden?.geo_datos_origen?.level2 : false}</p>
            <p style={{ fontSize: smallSizeFont }} className="mb-0 ml-2">Level 3: {orden?.orden?.geo_datos_origen?.level3 ?  orden?.orden?.geo_datos_origen?.level3 : false}</p>

            { !hide_address ? <Col md={12}><h6 style={{ fontSize: smallSizeFont }} className="mb-0">{mostrarDireccion(pedido)}</h6></Col> : false }
            </Card>
            </div>
            </Col>
            <Col xs={6} className=""><h6 style={{ fontSize: smallSizeFont, fontWeight : "bold" }} className="mb-0">{patente}</h6>
            <div>
            <p style={{ fontSize: smallSizeFont }} className="mb-0 ml-2">Destino: {orden?.orden?.billing?.address_1 ?  orden?.orden?.billing?.address_1 : false}</p>
            <Card className="">
            <p style={{ fontSize: smallSizeFont }} className="mb-0 ml-2">Level 1: {orden?.orden?.geo_datos?.level1 ?  orden?.orden?.geo_datos?.level1 : false}</p>
            <p style={{ fontSize: smallSizeFont }} className="mb-0 ml-2">Level 2: {orden?.orden?.geo_datos?.level2 ?  orden?.orden?.geo_datos?.level2 : false}</p>
            <p style={{ fontSize: smallSizeFont }} className="mb-0 ml-2">Level 3: {orden?.orden?.geo_datos?.level3 ?  orden?.orden?.geo_datos?.level3 : false}</p>

            { !hide_address ? <Col md={12}><h6 style={{ fontSize: smallSizeFont }} className="mb-0">{mostrarDireccion(pedido)}</h6></Col> : false }
            </Card>  */}

        </Row>
    </>)


    return <Card style={{backgroundColor: seleccionado ? 'gray' : 'white'}} className={`shadow h-100 hover p-3 ${seleccionado === true ? 'text-white' : 'text-primary'}`} onClick={() => clickPedido(pedido._id)}>
        <Row>
            <Col xs={7}>
                <p style={{ fontSize:9, fontWeight:'normal', borderRadius:2, backgroundColor: color_estado, padding: 6, color:'white' }} className="mb-2">{string_estado.toUpperCase()}</p>
            </Col>
            <Col xs={5} className="text-right"><h6 style={{ fontWeight:700, fontSize: 14, padding: '5px 0px' }} className='mb-0'><Link to={`/ordenes/${pedido._id}`} className={`${seleccionado === true ? 'text-white' : 'text-primary'}`}>{pedido.pedido} <i className="fa-solid fa-up-right-from-square"></i></Link></h6></Col>
            <Col xs={6}><h6 style={{ fontSize:13 }} className="mb-0">{destinatario}</h6></Col>
            <Col xs={6} className="text-right"><h6 style={{ fontSize:13 }} className="mb-2">{patente}</h6></Col>
            { !hide_address ? <Col md={12}><h6 style={{ fontSize:16, fontWeight:"bold" }} className="mb-0">{mostrarDireccion(pedido)}</h6></Col> : false }
            <Col md={12}><h6 style={{ fontSize:13, fontWeight:'lighter' }} className="mb-0">{ fechaATexto(pedido.createdAt)}</h6></Col>
            <Col md={12}>
            <MostrarGeoDatosComponent pedido={pedido} />
            </Col>
        </Row>
    </Card>
}

export default BoxList