import React, { Component, useState} from 'react'
import { connect, useSelector } from 'react-redux'
import { Card, Nav, Tab, Tabs } from 'react-bootstrap'
import Header from '../Header'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { key_local_storage_user } from '../../lib/backend/data'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import ApiKeysListado from '../../subComponents/integraciones/apikeys/apikeys_listado'
import Webhooks from '../../subComponents/integraciones/webhooks/webhooks'
import EstadosCarga from '../EstadosCarga'
import FlujosEstadosCarga from '../../subComponents/estados_carga/flujos'
import CamposPersonalizadosListado from '../../subComponents/campos-personalizados/list'
import CamposPersonalizadosGeneralListado from '../../subComponents/campos-personalizados-general/list'
import ExcelFormats from '../ExcelFormats'
import ExcelFormatosListado from '../../subComponents/excel-formats/listado'
import TiposServicioListado from '../../subComponents/ordenes/tipos_servicio/list'
import ListadoCanales from '../../subComponents/canales/listado'
import ServiciosListado from '../../subComponents/servicios/list'
import CostosListado from '../../subComponents/costos-tipos/list'
import { cerrarSesion } from '../../redux/actions/session'
import ListadoWhatsapp from '../../subComponents/listas-whatsapp/listadoWhatsapp/list'
import Personalizar from '../../subComponents/personalizar/personalizar'
import { useQuery } from '../../lib/helpers/router'
import EtiquetasListado from '../../subComponents/etiquetas-relaciones/list'
import HorarioLogisticaListado from '../../subComponents/horario-logistica/list'
import ConfiguracionTorreControl from '../../subComponents/estados_carga/configuracion_torre_control'
import ConfiguracionNotificaciones from '../../subComponents/notificaciones/configuracion_notificaciones'
import FormatoEditor from '../../subComponents/formatos/editor_formato'
import FormatosListado from '../../subComponents/formatos/list'
import MediosBox from '../../subComponents/general/mediosbox'

const Configuracion = ({ miusuario, cerrarSesion }) => {
  const user = useSelector(state => state.miusuario)
  // const [loadingUser, setLoadingUser] = useState(true)
  // const [loadingInsignias, setLoadingInsignias] = useState(true)
  // const [insignias, setInsignias] = useState([])
  const [selectedTab, setSelectedTab] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()
  let default_key = 'campos-personalizados'
  if(query.get("tab")) default_key = query.get("tab")
  
  const ifactive = (status) => {
    switch(status){
        case true:
            return <p className="nomargin" style={{ fontSize: 12 }} ><i style={{ color: '#00b23d'}} className="fas fa-check-circle"></i> ACTIVO</p>
        default:
            return <p className="nomargin" style={{ fontSize: 12 }} ><i className="far fa-clock"></i> INACTIVO</p>
    }
}
    
  const misDatos = () => {
      return <Row className="bienvenida">
          <Col xs={12} className="mt-3"> <h5>Mis datos</h5>  </Col>
          <Col md={3}>
              <Card >
                  <Card.Body>
                      <p className="text-primary nomargin" >Estado del usuario</p>
                      <p className="nomargin" style={{ fontSize: 12 }} >{ifactive(user.data.activo) }</p>
                  </Card.Body>
              </Card>
          </Col>
          <Col md={3}>
              <Card >
                  <Card.Body>
                      <p className="text-primary nomargin" >Email</p>
                      <p className="nomargin" style={{ fontSize: 12 }} >{user.data.email }</p>
                  </Card.Body>
              </Card>
          </Col>
      </Row>
  }

    const handleChangeTab = (e) => {
      const newTab = e;
      const queryParams = new URLSearchParams();
      queryParams.set('tab', newTab);
      navigate({
          pathname: location.pathname,
          search: `?${queryParams.toString()}`,
      });
      return setSelectedTab(newTab);
    }


        return(
              <div className="fluid">
          <h1 className="h2 text-primary" style={{fontWeight:700}}><i className="fa-solid fa-gear"></i> Configuración general</h1>
          <Tab.Container id="left-tabs-example" defaultActiveKey={default_key}>
        <Row>
          <Col sm={3}>
          <Nav variant="pills" className="flex-column" onSelect={handleChangeTab}>
              {/* <Nav.Item><Nav.Link eventKey="estados_carga">Estados de carga</Nav.Link></Nav.Item> */}
              {/* <Nav.Item><Nav.Link eventKey="flujos_carga">Flujo de estados de carga</Nav.Link></Nav.Item> */}
              <Nav.Item><Nav.Link eventKey="campos-personalizados">Campos personalizados de órdenes</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="campos-personalizados-general">Campos personalizados</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="tipos-servicio">Tipos de servicio</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="servicios">Servicios</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="canales">Canales</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="listas-whatsapp">Listas de whatsapp</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="formatos-excel">Formatos excel</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="tracking-page">Personalización</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="library">Biblioteca de medios</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="tags">Etiquetas</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="horario-logistica">Horario Logistica</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="torre-control">Torre de control</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="notificaciones">Notificaciones</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="formatos">Formatos</Nav.Link></Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              {/* <Tab.Pane eventKey="estados_carga" >
                  <EstadosCarga />
              </Tab.Pane> */}
              {/* <Tab.Pane eventKey="flujos_carga" >
                <FlujosEstadosCarga user={user} />
              </Tab.Pane> */}
              <Tab.Pane eventKey="campos-personalizados" >
                <CamposPersonalizadosListado token={user.tokenSession} condicion_default={{}} />
              </Tab.Pane>
              <Tab.Pane eventKey="campos-personalizados-general" >
                <CamposPersonalizadosGeneralListado token={user.tokenSession} condicion_default={{}} />
              </Tab.Pane>
              <Tab.Pane eventKey="canales" >
              <ListadoCanales />
              </Tab.Pane>
              <Tab.Pane eventKey="tipos-servicio" >
                <TiposServicioListado token={user.tokenSession} condicion_default={{ tipo: "logistica", sub_tipo: "tipos-servicio" }} />
              </Tab.Pane>
              <Tab.Pane eventKey="servicios" >
                <ServiciosListado condicion_default={{}} />
              </Tab.Pane>
              <Tab.Pane eventKey="formatos-excel" >
                <ExcelFormatosListado token={user.tokenSession} condicion_default={{}} />
              </Tab.Pane>
              <Tab.Pane eventKey="listas-whatsapp" >
                <ListadoWhatsapp />
              </Tab.Pane>
              <Tab.Pane eventKey="tracking-page" >
                <Personalizar />
              </Tab.Pane>
              <Tab.Pane eventKey="library" >
                <MediosBox />
              </Tab.Pane>
              <Tab.Pane eventKey="tags" >
                <EtiquetasListado />
              </Tab.Pane>
              <Tab.Pane eventKey="horario-logistica" >
                <HorarioLogisticaListado />
              </Tab.Pane>
              <Tab.Pane eventKey="torre-control" >
                <ConfiguracionTorreControl />
              </Tab.Pane>
              <Tab.Pane eventKey="notificaciones" >
                <ConfiguracionNotificaciones />
              </Tab.Pane>
              <Tab.Pane eventKey="formatos" >
                <FormatosListado />
              </Tab.Pane>
            </Tab.Content>
          </Col>
          </Row>
        </Tab.Container>
      </div>
    )
    }


  const mapStateToProps = (state) => {
      return {
          miusuario: state.miusuario
      }
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
          cerrarSesion: () => dispatch(cerrarSesion())
      }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Configuracion);